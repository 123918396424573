import './PatientList.css';
import React, { useState, useEffect }from 'react';
import { useNavigate } from 'react-router-dom';
import Patient from '../../common/Model/PatientModel';

function PatientList() {
  const navigate = useNavigate();
  const ptt: Patient[] = [];
  const [reviews, setReviews] = useState(ptt);

  const viewSubmitedDataSummary = (t:Patient) => {

    console.log(t);
    navigate('/patient', { state: t});
  }

  useEffect(() => {
    // Simulate a fetch call to load users data
    fetch('https://aireview-api.cansr.com/api/aireview/')
      .then(response => response.json())
      .then(data => {
        
        setReviews(data);
        
      });
      let ak:any = document.getElementById("patient-list-button");
      let bk: any = document.getElementById("patient-form-button");
      ak.style = "display:none";
      bk.style = "";
  }, []);
  return (
      <div className="row mt-5 justify-content-center">
        <div className="col-12">
          <div className='shadow p-3 mb-5 bg-body rounded'>
            <div className='p-5'>
              <div className="d-flex justify-content-between">
                  <div>
                     
                  </div>
              </div>
              <div className="row mt-3">
                <div className="col-6">
                  <h3 className="patient-list-lbl">Patient List</h3>
                </div>
               
                <div className="col-12">
									            <div className="card1 no-border">
													<div className="card-body1 ">
														<table className="table table-bordered">
															<thead>
																<tr>
																	<th>Patient ID</th>
																	<th>Tumour Type</th>
																	<th>Submitted On</th>
																	<th>Data and Review</th>
																</tr>
															</thead>
															<tbody id="patientListTB">
                              {reviews.map(user => (
                                <tr key={user._id}>
                                  <td>{user.patient_id}</td>
                                  <td>{user.tumour_type!="N/A"?user.tumour_type+ " tumour":user.tumour_type}</td>
                                  <td >{ new Date(user.submitted_on).toLocaleDateString() }</td>
                                  <td onClick={()=>viewSubmitedDataSummary(user)}><span className='text-primary' >View</span></td>
                                  
                                  </tr>
                              ))}
															</tbody>
														</table>
													
													</div>
												</div>
											</div>
              </div>
              
            </div>

          </div>

        </div>
      </div>
  );
}

export default PatientList;
