import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.css';
import Layout from './common/Layout/Layout';
import PatientDetail from './pages/PatientDetail/PatientDetail';
import PatientList from './pages/PatientList/PatientList';
import ReviewForm from './pages/ReviewForm/ReviewForm';

function App() {
  return (
    <div className="main-wrapper m-5">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<ReviewForm />} />
          <Route path="patient" element={<PatientDetail  />} />
          <Route path="patient-list" element={<PatientList />} />
          <Route path="*" element={<ReviewForm />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
