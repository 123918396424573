import Patient from '../../common/Model/PatientModel';
import './PatientDetail.css';
import { useLocation } from 'react-router-dom';
import React, { useEffect }from 'react';

function PatientDetail() {
  let location = useLocation();
  let person:Patient = location.state;
  useEffect(() => {
  let ak:any = document.getElementById("patient-list-button");
      let bk: any = document.getElementById("patient-form-button");
      ak.style = "";
      bk.style = "";
  });
  
  return (
    <div className="row mt-5 justify-content-center">
        <div className="col-12">
          <div className='shadow p-3 mb-5 bg-body rounded'>
            <div className='p-5'>
                <div className="d-flex justify-content-between">
                  <div>
                      <h3 className="mb-0 text-tonalblue" id="d-patient-id">Patient ID: <span className='font300'>{person.patient_id}</span></h3>
                  </div>
                </div>
                <div className="row mt-5">
        
                    <div className="col-6">
                      <h5>Submitted Data</h5>
                      <div id="d-submitted-data" className="mt-2 text-tonalblue-data">
                     
                          <p style={{whiteSpace: "pre-wrap"}} > {person.submitted_data} </p>
                      </div>
                    </div>
                    <div className="col-1">
                    </div>
                    <div className="col-5">
                      <h5>AI Tumour Review </h5>
                      <div id="d-summary" className="mt-2 text-tonalblue-data">
                        {person.review_data}
                      </div>
                    </div>
                    
                </div>
            </div>

          </div>

        </div>
      </div>
  );
}

export default PatientDetail;
