import './Header.css';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

function Header() {

  return (
    <div className="row">
      <div className='col-sm-12 col-md-6'>
      <img src={process.env.PUBLIC_URL+"/assets/images/logo/cansr_logo.png"} className="cw-15" alt="Cansr" />
      </div>
      <div className='col-sm-12 col-md-6 text-end mobile-mt-2'>
        <div className="inline-flex ">
          <Button id='patient-form-button' variant="outline-primary me-2"><Link className="no-color" to="/">Upload New Report</Link></Button> 
          <Button id='patient-list-button' variant="outline-primary"><Link className="no-color" to="/patient-list">View Patient List</Link></Button> 
        </div>
      </div>
    </div>
  );
}

export default Header;
