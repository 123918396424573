import './ReviewForm.css';
import React, { useState, useEffect }from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import { ProgressBar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function ReviewForm() {

 
  const [theprogress, setTheprogress] = useState(0);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [show, setShow] = useState(false);
 

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleInputChange = (event:any) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const saveData = (ddt:any) => {
   
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    fetch("https://aireview-api.cansr.com/api/aireview/", {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify(ddt),
      redirect: 'follow'
    })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        setTheprogress(100);
        setTimeout(()=>{
          handleClose();
          navigate('/patient', { state: result});
        }, 1000);
      })
      .catch(error => console.log('error', error));

  }

  const handleSubmit = (event:any) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    setTheprogress(5);
    handleShow();
    console.log(formData);
    saveData(formData);
    
  }
  const handleChange = (event:any) => {
   
    const form = event.currentTarget;
    setButtonDisabled(!form.checkValidity());
  }

  useEffect(() => {
    let ak:any = document.getElementById("patient-list-button");
      let bk: any = document.getElementById("patient-form-button");
      ak.style = "";
      bk.style = "display:none";
  });

  return (
    <div className="row mt-5 justify-content-center">
      <div className="col-12 col-md-7">
        <div className='shadow p-3 mb-5 bg-body rounded'>
          <div className='p-5'>
            <div className="d-flex justify-content-between">
                <div>
                    <h4 className="mb-0 text-tonalblue">Pancreatic Cancer - Localised or locally advanced tumour review (AI)</h4>
                    
                </div>
            </div>
            <div>
            <Form method='POST' onChange={handleChange} noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                
                <Form.Group className='mt-4' as={Col} md="7" controlId="validationCustom01">
                  <Form.Control
                    name="patientid"
                    required
                    type="text"
                    placeholder="Patient ID (Mandatory)"
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mt-4' as={Col} md="12" controlId="validationCustom02">
                  <label className='mb-2'>Provide written radiology report</label>
                  <Form.Control
                    name="submitteddata"
                    required
                    as="textarea" rows={5}
                    placeholder="Copy and paste your text here"
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Button className='mt-4' type="submit" disabled={isButtonDisabled} >SUBMIT</Button>
            </Form>

            </div>
          </div>

        </div>

      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        
        <Modal.Body className='p-15'>
        <h3 className='text-center'>Processing...</h3>
          <ProgressBar now={theprogress} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{window.location.reload();}}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReviewForm;
